
export default function SHIFTS(props){
    const clickEvent = (e) => {
        if (!e.target.className.includes(' text-light bg-warning'))
            e.target.className += ' text-light bg-warning'
        else
            e.target.className = 'cells'
        
    }

    const weekdays = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']
    
    return (
        <div className="table-responsive">
            <table className="table table-bordered border-light bg-secondary text-light">
                <thead>
                    <tr>
                        <th className="bg-dark text-center p-1" colSpan={'100%'}>
                        {/* { props.data[1] ? props.data[1][0][props.index]: <></>}  */}
                        { props.weekday } <span>
                                <button 
                                className="btn bg-secondary text-light p-0"
                                onClick={(e) => {
                                    const table = e.currentTarget.parentElement.parentElement.parentElement.parentElement.parentElement
                                    const tbody = table.childNodes[1]
                                    if (tbody.childNodes.length > 1)
                                        return
                                    const input = document.createElement('input')
                                    input.id = props.weekday
                                    input.className = 'form-control'
                                    const td = document.createElement('td')
                                    td.colSpan = 3 
                                    const tr = document.createElement('tr')
                                    td.appendChild(input)
                                    tr.appendChild(td)
                                    tbody.appendChild(tr)
                                    console.log(table.childNodes[1])
                                }}>+REMARK</button>
                            </span>
                        </th> 
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="cells" onClick={(e) => {clickEvent(e)}}>OPENING</td>
                        <td className="cells" onClick={(e) => {clickEvent(e)}}>MID</td>
                        <td className="cells" onClick={(e) => {clickEvent(e)}}>CLOSING</td>
                    </tr>
                </tbody>
            </table> 
            
        </div>
    )
}