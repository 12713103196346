import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import './App.css';
import './bg.css'
import SHIFTS from './components/SHIFTS';
import DISPLAY from './components/DISPLAY';
import axios from 'axios'
import ADMIN from './components/ADMIN';


function App() {
  const [code, setCode] = useState('')
  const admin = 'Bounce0901'
  const [data, setData] = useState([])
  const [name, setName] = useState('')
  
  const dates = [ 22, 23, 24, 25, 26, 27, 28]
  const weekdays = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']
  const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
  
  const fetchData = async () => {
    await axios.get('https://bouncereactapp.onrender.com/api').then(res => {setData(res.data)})
    console.log(data)
  }

  const submitEvent = async () => {
    
    console.log(name)
    const list = []
    
    const listOfCells = document.getElementsByClassName('cells')
    console.log(listOfCells)
    for (var i = 0; i < 7; i++){
      const rows = []
      for (var j = 0; j < 3; j++){
        if (listOfCells[(i * 3) + j].className.includes('bg-warning'))
          rows.push(1)
        else
          rows.push(0)
      }
      list.push(rows)
    }

    const listOfRemarks = [[], []]
    for (var i = 0; i < weekdays.length; i++){
      if (document.getElementById(weekdays[i])){
        listOfRemarks[1].push(document.getElementById(weekdays[i]).value)
        listOfRemarks[0].push(weekdays[i])
    }}
  
    console.log(list)
    await axios.post('https://bouncereactapp.onrender.com', {name: name, shifts: list, remarks: listOfRemarks})
    window.location.reload(true)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className="container-fluid">
      <div className='row mt-3 mb-5'>
          <div className='col'>

            
            { weekdays.map((i, index) => {return <SHIFTS weekday={i} data={data} date={dates[index]} index={index}/>}) } 

            <div 
            className='mb-3 input-group mx-auto'
            style={{maxWidth: '500px'}}>
            
                <button 
                className='btn btn-primary text-light border border-light'
                type={'submit'}
                onClick={ submitEvent }>SUBMIT</button>
        
            <input 
              id='name'
              type={'text'}   
              className={'form-control bg-dark text-light text-center'}
              placeholder={'name'}
              onChange={(e) => {
                setName(e.target.value)
                setCode(e.target.value) 
              }}>
              </input>
              
            </div>
            
          </div>

          
          { code === admin? 
            <div className='col'>
            <div>
              { weekdays.map((i, index) => {return <DISPLAY weekday={i} data={data} date={dates[index]} index={index}/>}) }
              <div 
                className='input-group mx-auto'
                style={{maxWidth: '500px'}}>
                  <button 
                  className='btn btn-primary border border-light'
                  style={{width: '130px', height: '50px'}}
                  onClick={() => {
                    const name = document.getElementById('delete1').value
                    console.log(name)
                    axios.post('https://bouncereactapp.onrender.com/delete', {name: name})
                    window.location.reload(true)
                  }}>DELETE ONE</button>
                  <input 
                  id='delete1'
                  className='form-control bg-dark text-light text-center'
                  style={{height: '50px'}}
                  placeholder='name'></input>
              </div>
              <div
              className='input-group mx-auto mt-3' 
              style={{width: '500px'}}>
                <button 
                className='btn btn-danger'
                onClick={() => {
                    axios.delete('https://bouncereactapp.onrender.com')
                }}
                style={{width: '130px', height: '50px'}}>CLEAR ALL</button>
              </div>
              
            </div></div>: <div></div>}
        
          
          
        </div>
        
      
    </div>
  );
}

export default App;
