
export default function DISPLAY(props){
    const weekday = props.weekday
    var data = props.data
    const shifts = ['OPENING', 'MID', 'CLOSING']
    const colors = ['bg-warning', 'bg-info', 'bg-danger']

    const list = []

    if (data != []){
        data[0].map((item) => {
            const rows = []
            if (item.day === weekday){
                for (var i = 0; i < 3; i++){
                    if (item.shifts.includes(shifts[i]))
                        rows.push(<td className={colors[i]}>{shifts[i]}</td>)
                    else 
                        rows.push(<td></td>)
                }
                list.push(
                <tr className="text-light bg-secondary">
                    <td colSpan='100%' className="p-0">
                        {item.name}
                    </td>
                </tr>)
                list.push(<tr className="text-light">{ rows }</tr>)
                if (item.remark)
                    list.push(
                        <tr>
                            <td 
                            className="text-light p-0"
                            colSpan={'100%'}>{ item.remark }</td>
                        </tr>
                    )
            }
            return 0
        })
    }
    return (
        <div className="table-responsive">
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th colSpan={'100%'} className="text-light bg-dark p-1">
                            {/* {data[1][0][props.index]? data[1][0][props.index]: <></>}  */}
                            {props.date} {weekday}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    { list }
                </tbody>
            </table>
        </div>
    )
}